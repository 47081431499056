import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { analyticsEvent } from "../../redux/actions/analytics";
import { api } from "../../api/base";
import { StyledAuthContainer } from "../../components/micro/micro.styled";
import { AuthHeader } from "../../components/AuthHeader";
import { useWindowDimensions } from "../../utils/hooks/windowDimensionsHook";
import ChevronIcon from "../../assets/download-arrow.png";
import MacLaptop from "../../assets/mac-laptop.png";
import Rec1 from "../../assets/rec1.png";
import { Backdrop, Box, CircularProgress, Button } from "@mui/material";
import { useSnackBar } from "../../context/SnackBar";
import {useDeviceInfo, getDownloadLink} from "../../utils/hooks/useDeviceInfo";

export const UniversalDownload = () => {
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions();
  const { showSnackBar } = useSnackBar();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = decodeURIComponent(searchParams.get("email") || "").replaceAll(
    " ",
    "+",
  );
  const autoDownload = searchParams.get("auto-download");

  const deviceInfo = useDeviceInfo();

  useEffect(() => {
    const isAutoDownloadDisabled =
      typeof autoDownload === "string" && autoDownload === "false";
    if (
      searchParams.get("email") &&
      !isAutoDownloadDisabled
    ) {
      dispatch({
        type: "DOWNLOAD_APP",
        payload: {
          email: decodeURIComponent(searchParams.get("email") || "").replaceAll(
            " ",
            "+",
          ),
        },
      });
    }
  }, []);

  const handleDownloadClick = () => {
    if (email) {
      api
        .post("/auth/download", {
          email: email,
          os: "universal",
          location: "email",
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };


  return (
    <StyledAuthContainer>
      <AuthHeader
        step={"6"}
        showProgress
        proInvite={false}
        showBackButton={false}
        backButtonHandler={() => {}}
        setStep={() => {}}
        width={width}
      />{" "}
        <div className="p-[30px]">
          <div className="flex flex-col gap-[30px] text-center lg:flex-row">
            <div className="pt-[20px] lg:w-[300px]">
              <img
                className="mx-auto mb-[25px] h-[131px] w-[237px] object-contain"
                src={MacLaptop}
                alt=""
              />
              <p className="mb-[40px] text-[18px] font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC]">
                Open the Density <br />
                Desktop app
              </p>
              <a
                href={getDownloadLink(deviceInfo)}
                download
              >
                <Button
                  fullWidth
                  variant={"contained"}
                  className="buttonWithIcon"
                  onClick={handleDownloadClick}
                  sx={{ mb: "36px", height: "44px", fontSize: "13px" }}
                >
                  <span> Download </span>
                </Button>
              </a>
            </div>
            <div className="flex h-[50px] items-center lg:h-[276px]">
              <img
                className="mx-auto h-[24px] w-[12px] rotate-90 lg:rotate-0"
                src={ChevronIcon}
                alt=""
              />
            </div>
            <div className="flex h-[200px] flex-col lg:h-[276px] lg:w-[300px]">
              <div className="mx-auto mt-auto flex h-[84px] w-[84px] items-center justify-center rounded-full border-[1px] border-[#62667C] lg:mb-[79px]">
                <span className="text-[34px] font-normal leading-[36px] text-white">
                  2
                </span>
              </div>
              <p className="mb-[16px] mt-auto text-[18px] font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC] lg:mb-[30px]">
                Log in using:
                <br />
                {email}
              </p>
              <p className="text-[12px] font-medium leading-[18px] tracking-[0.1px] text-[#FCFCFC99]">
                You already have an account.{" "}
                <span
                  className="cursor-pointer text-[#737BAE] underline"
                  onClick={() => navigate("/login")}
                >
                  Log in here.
                </span>
              </p>
            </div>
            <div className="flex h-[50px] items-center lg:h-[276px]">
              <img
                className="mx-auto h-[24px] w-[12px] rotate-90 lg:rotate-0"
                src={ChevronIcon}
                alt=""
              />
            </div>
            <div className="relative flex h-[150px] flex-col lg:h-[276px] lg:w-[300px]">
              <div className="mx-auto mt-auto flex h-[84px] w-[84px] items-center justify-center rounded-full border-[1px] border-[#62667C] lg:mb-[79px]">
                <span className="text-[34px] font-normal leading-[36px] text-white">
                  3
                </span>
              </div>
              <p className="mt-auto text-[18px] font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC] lg:mb-[30px]">
                Point and click to create a set in the DAW
              </p>
            </div>
            <div className="flex h-[50px] items-center lg:h-[276px]">
              <img
                className="mx-auto h-[24px] w-[12px] rotate-90 lg:rotate-0"
                src={ChevronIcon}
                alt=""
              />
            </div>
            <div className="pt-[20px] lg:w-[300px]">
              <img
                className="mx-auto mb-[50px] h-[109px] w-[120px] object-contain"
                src={Rec1}
                alt=""
              />
              <p className="mb-[40px] text-[18px] font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC]">
                Connect your laptop to your decks to record a set.
                <br />
                <br />
                <br />
                Upgrade to the Density Hardware for club environments. Available
                in the app.
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-center gap-[30px] text-center lg:flex-row">
            <div className="pt-[20px]">
              <div className="mt-[70px]">
                <p className="text-[16px] font-normal leading-[24px] tracking-[0.15px] text-[#DCDEE4]">
                  Need help from a human DJ?{" "}
                  <a
                    className="text-[#99A0C5]"
                    href="mailto:support@density.one"
                  >
                    support@density.one
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(2px)",
        }}
        open={loading}
      >
        <Box
          width={"100px"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
        >
          <CircularProgress
            sx={{
              marginBottom: "20px",
            }}
            color="inherit"
          />
          Loading ...
        </Box>
      </Backdrop>
    </StyledAuthContainer>
  );
};

import axios from "axios";

export const api = axios.create({
  // baseURL: "https://api.density.one/api",
  baseURL: "https://qa.api.density.one/api",
  // baseURL: "http://localhost:8080/api",
  headers: {
    "Content-type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

import { Button, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { LandingNavbar } from "../../components/LandingNavbar";

import HardwareDensity from "../../assets/Hardware-with-shadow.png";
import WithDensityHardware from "../../assets/with-density-hardware.svg";
import LaptopRecording from "../../assets/laptop-recording.svg";

import {
  StyledDensityHardwareContainer,
  StyledLandingBoldParagraph,
  StyledLandingPageWrapper,
  StyledLandingVideoWrapper,
} from "./landing.styled";
import { useWindowDimensions } from "../../utils/hooks/windowDimensionsHook";
import { Footer } from "../../components/Landing/Footer";
import { useDispatch } from "react-redux";
import { TEXTS } from "./text";
import ModalVideo from "react-modal-video";
import NewCoverImage from "../../assets/new-cover-image.png";
import FindYourTracks from "../../assets/find-your-tracks.svg";
import RemoveUnreleasedTrack from "../../assets/remove-unreleased-tracks.svg";
import NeverLoseASetAgain from "../../assets/never-lose-a-set.png";
import EnsureConsistentLoudness from "../../assets/ensure-loudness.svg";
import RiderFriendly from "../../assets/rider-friendly.svg";
import PlayIcon from "../../assets/Pause Controls.svg";
import FullSetup from "../../assets/full-setup.svg";
import EditRecordings from "../../assets/edit-recordings-density-daw.png";
import EditRecordingsSmall from "../../assets/edit-recordings-density-daw-small.png";
import { InfoShowcase } from "../../components/Info/InfoShowcase";
import { StyledAuthButton } from "../../components/micro/micro.styled";
import { Header } from "../../components/Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { api } from "../../api/base";
import { decodeToken } from "react-jwt";
import { analyticsEvent } from "../../redux/actions/analytics";
import { setLoginModalOpen } from "../../redux/actions/app";
import { useUserContext } from "../../context/UserContext";
import { InfoCardWithAction } from "../../components/Info/InfoCardWithAction";
import { InfoPanel } from "../../components/Info/InfoPanel";

import { isBFSale } from "../../utils/sales";
import {useDeviceInfo, getDownloadLink} from "../../utils/hooks/useDeviceInfo";

export const HardwareLandingPage = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const contentLoaded = Boolean(localStorage.getItem("content-loaded"));
  const [loading, setLoading] = useState(!contentLoaded);
  const videoRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const { isUserLoggedIn, fetchingUserData, user } = useUserContext();
  const [loadingPurchase, setLoadingPurchase] = useState(false);

  const hardwareButtonRef = useRef<any>(null);

  const isUserPremium = (user?.plan || "free") === "premium";
  const isDownloadCheckout = isBFSale() && !isUserPremium;
  const deviceInfo = useDeviceInfo();

  useEffect(() => {
    dispatch(
      analyticsEvent("EVENT", "Hardware Page Visited", {
        page: "Hardware Page",
      }),
    );
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > prevScrollPos) {
        setVisible(true);
      } else {
        setVisible(false);
      }

      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll, true);

    if (loading) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1500);

      setTimeout(() => {
        setLoading(false);
        localStorage.setItem("content-loaded", "true");
      }, 2500);
    }

    return () => window.removeEventListener("scroll", handleScroll, true);
  }, [dispatch, loading, prevScrollPos]);

  const isHardwareButtonDisabled = () => {
    return loading || fetchingUserData || loadingPurchase;
  };

  const buyHardwarePlan = async (): Promise<void> => {
    setLoadingPurchase(true);

    if (!isUserLoggedIn) {
      navigate("/onboarding/1?plan=hardware", { state: { from: "hardware" } });
      return;
    }

    try {
      const token = localStorage.getItem("access_token");
      //@ts-ignore
      const decodedToken = decodeToken(token);
      //@ts-ignore
      const email = decodedToken?.email;
      const { data }: AxiosResponse<any, any> = await api.post(
        "chargebee/checkout-hardware",
        {
          redirect_url: `${process.env.REACT_APP_URL}/account/upgrade-success?plan=hardware&instructions=true`,
          email,
        },
      );
      navigate(`/account/checkout`, {
        state: {
          url: data.hosted_page.url,
          additionalText: "Hardware - $300",
          title: "REC-1",
          cancelUrl: "/hardware",
        },
      });
    } catch (err) {
      console.error(err);
      setLoadingPurchase(false);
    }
  };

  const handlePurchaseAction = () => {
    dispatch(
      analyticsEvent("INPUT", "Hardware - Get Hardware Clicked", {
        page: "Hardware",
      }),
    );
    buyHardwarePlan();
  };

  const getDensity = () => {
    const flow = props.getDensity ? "invited" : "primary"; // Determine the flow based on the URL

    if (props.getDensity) {
      props.getDensity();
    } else {
      localStorage.removeItem("landing");
      dispatch(
        analyticsEvent("CLICK", "Get Density Clicked", {
          page: "Landing Page",
          flow: flow, // Use the determined flow
        }),
      );

      if (isDownloadCheckout) {
        navigate(`/pricing`);
      } else if (isUserLoggedIn) {
        localStorage.setItem("onboarding_in_progress", "true");
        navigate(`/onboarding/6?instructions=true`);
      } else {
        const ref = searchParams.get("ref");
        if (ref) {
          navigate(`/onboarding/0/${ref}`);
        } else {
          navigate(`/onboarding/1`);
        }
      }
    }
  };

  const handleLearnMore = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const handleOpenWatchHowItWorksLink = () => {
    if (videoRef.current) {
      videoRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    setOpen(true);
  };

  const getPurchaseHardwareCardInfo = () => {
    const info = { ...TEXTS.SECOND_INFO_CARD, title: "$300", description: [] };
    return info;
  };

  useEffect(() => {
    if (fetchingUserData) {
      return;
    }

    if (isUserLoggedIn) {
      switch (searchParams.get("purchase")) {
        case "hardware":
          hardwareButtonRef.current.click();
          break;
        default:
          break;
      }
    } else {
      if (searchParams.has("purchase")) {
        dispatch(setLoginModalOpen(true));
      }
    }
  }, [fetchingUserData, isUserLoggedIn]);

  return (
    <>
      <StyledLandingPageWrapper className="landing-page-wrapper">
        {isUserLoggedIn ? (
          <Header />
        ) : (
          <LandingNavbar
            visible={visible}
            ww={width}
            getDensity={() => {
              return handlePurchaseAction();
            }}
          />
        )}
        <main
          style={{ maxWidth: 1360, margin: "auto" }}
          className="p-[1.27rem]"
        >
          <StyledDensityHardwareContainer>
            <img
              className="hardware-density-image  -translate-x-[20px] pb-3 sm:-translate-x-[44px] md:-translate-x-[56px]"
              src={HardwareDensity}
              alt=""
            />
            <Typography
              className="density-hero-heading hardware-density-heading"
              variant="h1"
              component="h1"
              sx={{
                background:
                  "linear-gradient(182.83deg, rgba(218, 220, 240, 0.8) 12.57%, rgba(168, 171, 219, 0.5) 88.12%)",
                "-webkit-background-clip": {
                  md: "text",
                  xs: "text",
                },
                "-webkit-text-fill-color": {
                  md: "transparent",
                  xs: "transparent",
                },
              }}
            >
              The end of <br />
              “it didn’t record”
            </Typography>
          </StyledDensityHardwareContainer>

          <section className="mt-[38px] md:mt-24">
            <div className="flex flex-col justify-between gap-[26px] lg:flex-row">
              <InfoCardWithAction
                data={{
                  ...TEXTS.FIRST_INFO_CARD,
                  handleElementAction: handleOpenWatchHowItWorksLink,
                  className: "flex-1 ",
                  isBold: true,
                }}
              ></InfoCardWithAction>
              <div
                className={`m-w-max box-border flex w-[100%] flex-col items-center justify-center rounded-[30px] bg-[#FFFFFF0D] px-[28px] px-[59px] pt-[48px] text-center text-primary-purple sm:flex-row `}
                style={{ flex: 2 }}
              >
                <div className="mx-auto flex h-[100%] w-[100%] flex-col items-center justify-center text-center">
                  <h2 className="whitespace-nowrap pb-[16px] text-center font-['PPRightGrotesk-Bold'] text-[18px]  font-black leading-tight !text-primary-purple">
                    {"Density hardware"}
                  </h2>
                  <p className="pb-[64px] text-left font-inter text-[14px] leading-[24px] tracking-wide !text-base-gray">
                    {isBFSale() && <br />}
                    $300
                  </p>
                  <div className="w-[100%]">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePurchaseAction}
                      disabled={isHardwareButtonDisabled()}
                      sx={{
                        width: "100%",
                        height: {
                          md: 44,
                          xs: 36,
                        },
                        margin: 0,
                        padding: {
                          xs: "10px 16px",
                          md: "15px",
                        },
                        backgroundColor: "#c1c5dd",
                        mb: "32px",
                        fontSize: {
                          md: "13px",
                        },
                      }}
                    >
                      Purchase
                    </Button>
                  </div>
                  <img
                    src={WithDensityHardware}
                    alt="with density hardware"
                    className="w-[186px]"
                  />
                </div>

                <div className="mx-[45px] flex w-[100%] flex-row items-center pt-[41px] sm:h-[100%] sm:w-[27px] sm:flex-col">
                  <div className="sm:margin-auto flex h-[1px] w-[100%] flex-row  bg-[#FFFFFF] opacity-10 sm:h-[120px] sm:w-[1px] sm:flex-col" />
                  <p className=" m-4 my-[10px] h-[32px] text-left font-inter text-[14px] leading-[32px] tracking-wide !text-base-gray ">
                    OR
                  </p>
                  <div className="sm:margin-auto flex h-[1px] w-[100%] flex-row  bg-[#FFFFFF] opacity-10 sm:h-[120px] sm:w-[1px] sm:flex-col" />
                </div>

                <div className="mx-auto flex h-[100%] w-[100%] flex-col items-center text-center">
                  <h2 className="whitespace-nowrap pb-[8px] text-center font-['PPRightGrotesk-Bold'] text-[18px]  font-black leading-tight !text-primary-purple">
                    {"Laptop recording"}
                  </h2>
                  <p className="pb-[8px] text-left text-center font-inter text-[14px] leading-[24px] tracking-wide !text-base-gray">
                    Basic - Free <br />
                    Premium -{" "}
                    <span className={isBFSale() ? "line-through" : ""}>
                      $15/mth
                    </span>
                    <br />
                    {isBFSale() && (
                      <span className="!text-alert-orange-800">
                        Holiday Sale - 3 Months for $1.50
                      </span>
                    )}
                  </p>
                  <a
                    href="/pricing"
                    className="pb-[16px] text-left text-center font-inter text-[14px] leading-[24px] tracking-wide underline"
                    style={{ color: "#575F9E" }}
                  >
                    Pricing
                  </a>
                  <div className="w-[100%]">
                    <a
                      href={`${isUserLoggedIn && !isDownloadCheckout ? getDownloadLink(deviceInfo) : ""}`}
                      download={false}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={getDensity}
                        sx={{
                          width: "100%",
                          height: {
                            md: 44,
                            xs: 36,
                          },
                          margin: 0,
                          padding: {
                            xs: "10px 16px",
                            md: "15px",
                          },
                          backgroundColor: "#c1c5dd",
                          mb: "16px",
                          fontSize: {
                            md: "13px",
                          },
                        }}
                      >
                        Download
                      </Button>
                    </a>
                  </div>
                  <img
                    src={LaptopRecording}
                    alt="with density hardware"
                    className="w-[186px] pt-[16px]"
                  />
                </div>
              </div>
              {/* <InfoCardWithAction
                data={{ ...getPurchaseHardwareCardInfo(), handleElementAction: handlePurchaseAction }}
              ></InfoCardWithAction> */}
            </div>
          </section>

          <section className="mt-[38px] md:mt-24">
            <StyledLandingVideoWrapper ref={videoRef}>
              <ModalVideo
                channel="youtube"
                isOpen={isOpen}
                videoId="Ix7_3nIACpU"
                youtube={{
                  autoplay: 1,
                  showinfo: 0,
                  controls: 1,
                }}
                onClose={() => setOpen(false)}
              />
              <img className="landing-video-image" src={NewCoverImage} alt="" />

              <button
                className="landing-video-play-icon"
                onClick={() => setOpen(true)}
              >
                <img
                  role="presentation"
                  src={PlayIcon}
                  onClick={() => setOpen(true)}
                  alt=""
                />
              </button>
              <StyledLandingBoldParagraph>
                {"Density explained in 90 seconds"}
              </StyledLandingBoldParagraph>
            </StyledLandingVideoWrapper>
          </section>

          <section className="mt-[38px] md:mt-24">
            <InfoPanel
              data={{
                ...TEXTS.FIRST_INFO_PANEL,
                descriptionLink: {
                  title: "Download now",
                  url: "/onboarding/1",
                  action: getDensity,
                },
              }}
            >
              <img src={FullSetup} alt="the full setup" />
            </InfoPanel>
          </section>

          <section className="mt-[38px] md:mt-24">
            <InfoShowcase data={{ ...TEXTS.FIRST_INFO_SHOWCASE }}>
              <div className="flex justify-end pt-[4.7rem]">
                <img
                  src={FindYourTracks}
                  alt="find your tracks"
                  className={"w-[683px]"}
                />
              </div>
            </InfoShowcase>
          </section>

          <section className="mt-[38px] md:mt-24">
            <InfoShowcase data={{ ...TEXTS.SECOND_INFO_SHOWCASE, rtl: true }}>
              <div className="p-6">
                <img
                  src={RemoveUnreleasedTrack}
                  alt="remove unreleased tracks"
                  className={"w-[683px]"}
                />
              </div>
            </InfoShowcase>
          </section>

          <section className="mt-[38px] md:mt-24">
            <InfoShowcase data={{ ...TEXTS.THIRD_INFO_SHOWCASE, shadow: true }}>
              <div className="flex justify-end pt-[4.7rem]">
                <img
                  src={NeverLoseASetAgain}
                  alt="never lose a set again"
                  className={"w-[683px]"}
                />
              </div>
            </InfoShowcase>
          </section>

          <section className="mt-[38px] md:mt-24">
            <InfoShowcase data={{ ...TEXTS.FOURTH_INFO_SHOWCASE, rtl: true }}>
              <div>
                <div className="m-8">
                  <div className="w-[140px] rounded-[45px] border border-[#FFFFFF1A] px-4 py-2 text-center text-[10px] leading-[23px] text-[Inter]">
                    {"LOUDNESS METER"}
                  </div>
                </div>
                <img
                  src={EnsureConsistentLoudness}
                  alt="ensure consistent loudness"
                />
              </div>
            </InfoShowcase>
          </section>

          <section className="mt-[38px] md:mt-24">
            <InfoShowcase data={{ ...TEXTS.FIFTH_INFO_SHOWCASE }}>
              <div className="py-5 pb-11 pr-11">
                <img src={RiderFriendly} alt="ensure consistent loudness" />
              </div>
            </InfoShowcase>
          </section>

          <section className="mt-[38px] md:mt-24">
            <div className="flex rounded-[30px] bg-gradient-to-b from-[#686FEF] to-transparent">
              <div className="m-auto flex w-[100%] flex-col md:flex-row">
                <div className="m-auto mt-10 flex flex-col items-start justify-center md:my-10">
                  <h3 className="mb-4 max-w-[15ch] text-center font-['PPRightGrotesk-Bold'] text-[34px] font-black leading-[38.85px] 900px:text-left">
                    Record and edit in the Density DAW
                  </h3>
                  <div className="mx-auto 900px:m-0">
                    <StyledAuthButton
                      variant="contained"
                      color="primary"
                      onClick={handleLearnMore}
                      sx={{
                        width: 150,
                        height: 36,
                        backgroundColor: "#c1c5dd",
                        margin: 0,
                        padding: "6px",
                        fontSize: {
                          xs: "10px",
                          md: "11px",
                        },
                        lineHeight: "16px",
                        letterSpacing: "1.25px",
                        fontWeight: 600,
                        display: "flex",
                        alignSelf: "self-start",
                      }}
                    >
                      {"Learn More"}
                    </StyledAuthButton>
                  </div>
                </div>

                <div className="mt10 mt-[50px] max-w-[692.63px]">
                  <img
                    src={EditRecordingsSmall}
                    alt="give it a try"
                    className="w-[692.63px]"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="mt-[38px] md:mt-24">
            <InfoShowcase
              data={{ ...TEXTS.SIXTH_INFO_SHOWCASE }}
              customClassName={"bg-primary-gradient"}
            >
              <div className="p-5">
                <img
                  className="-translate-x-[24px]"
                  src={HardwareDensity}
                  alt="give it a try"
                  width={600}
                />
              </div>
              <div className="flex">
                <StyledAuthButton
                  ref={hardwareButtonRef}
                  variant="contained"
                  color="primary"
                  onClick={handlePurchaseAction}
                  disabled={isHardwareButtonDisabled()}
                  sx={{
                    width: 150,
                    height: 36,
                    backgroundColor: "#c1c5dd",
                    margin: { xs: "auto", md: 0 },
                    padding: "6px",
                    fontSize: {
                      xs: "10px",
                      md: "11px",
                    },
                    lineHeight: "16px",
                    letterSpacing: "1.25px",
                    fontWeight: 600,
                    display: "flex",
                  }}
                >
                  {"Purchase"}
                </StyledAuthButton>
              </div>
            </InfoShowcase>
          </section>

          <Footer />
        </main>
      </StyledLandingPageWrapper>
    </>
  );
};

import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDispatch } from "react-redux";

import WinLaptop from "../../assets/win-laptop.png";
import MacLaptop from "../../assets/mac-laptop.png";
import FinalStepLaptopAndRec from "../../assets/final-step-laptop-rec1.svg";
import { useSnackBar } from "../../context/SnackBar";
import { api } from "../../api/base";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import ChevronIcon from "../../assets/download-arrow.png";
import DensityLogo from "../../assets/Density-logo.png";

import { analyticsEvent } from "../../redux/actions/analytics";
import { useDeviceInfo, getDownloadLink } from "../../utils/hooks/useDeviceInfo";
import { DeviceType, OS } from "../../types/globals";
import { useOnboarding } from "../../utils/hooks/useOnboarding";
import { GTMEvent, gtmTriggerEvent } from "../../utils/gtm";

export const FinalStep = (props: {
  clearData: () => Promise<void> | void;
  handleContinue: () => void;
  backToEmail: () => void;
  setAddedToWaitlist: (value: boolean) => void;
  addedToWaitlist: boolean;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [showDownloadInstructions, setShowDownloadInstructions] = useState(
    localStorage.getItem("retry_email") ||
      localStorage.getItem("userExists") ||
      searchParams.get("instructions") ||
      false,
  );
  const { showSnackBar } = useSnackBar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userEmail = localStorage.getItem("user_email");
  const referrer = localStorage.getItem("referrer") || "";
  const isReferrerDensity = !referrer || referrer === "Density";
  const isInvite = !isReferrerDensity;
  const { isComingFromHardware } = useOnboarding();
  const [showModal, setShowModal] = useState(false); // State for showing the modal

  const deviceInfo = useDeviceInfo();

  useEffect(() => {
    console.log(
      searchParams.get("waitlist") === "true",
      !props.addedToWaitlist,
      !showModal,
    );
    if (
      searchParams.get("waitlist") === "true" &&
      !props.addedToWaitlist &&
      !showModal
    ) {
      setShowModal(true); // Show the modal when the waitlist parameter is true
    } else {
      setShowModal(false);
    }
  }, [searchParams.get("waitlist")]);

  useEffect(() => {
    const accessTokenToSet = localStorage.getItem("access_token_to_set");
    const refreshTokenToSet = localStorage.getItem("refresh_token_to_set");

    if (accessTokenToSet) {
      localStorage.setItem("access_token", accessTokenToSet);
      localStorage.removeItem("access_token_to_set");
    }

    if (refreshTokenToSet) {
      localStorage.setItem("refreh_token", refreshTokenToSet);
      localStorage.removeItem("refreh_token_to_set");
    }

    if (isComingFromHardware) {
      dispatch(
        analyticsEvent("EVENT", "Hardware purchased", {
          page: "Hardware",
        }),
      );
    }
  }, []);

  const handleDownloadClick = (location: string) => {
    if (!showDownloadInstructions) {
      dispatch(
        analyticsEvent("EVENT", "Instruction Page Visited", {
          page: "Instruction Page",
        }),
      );
    }
    setShowDownloadInstructions(true);

    dispatch(
      analyticsEvent("EVENT", "Download Density App", {
        "OS Downloaded": "Universal",
        location,
      }),
    );

    api
      .post("/auth/download", {
        email: userEmail,
        os: "universal",
      })
      .then((res) => {
        // console.log(res);
        dispatch(
          analyticsEvent("EVENT", "Download Density App", {
            "OS Downloaded": "Universal",
            location,
          }),
        );
        gtmTriggerEvent(GTMEvent.Download);
      })
      .catch((err) => {
        // console.log(err);
        dispatch(
          analyticsEvent("EVENT", "Download Density App Failed", {
            "OS Downloaded": "Universal",
            location,
          }),
        );
      });
  };

  const changeEmail = () => {
    if (!localStorage.getItem("access_token")) {
      navigate("/login?email=change");
      return;
    }
    localStorage.setItem("retry_email", "true");
    props.backToEmail();
  };

  if (props.addedToWaitlist) {
    return (
      <div className="md:mt-[89px]">
        <div className="max-w-full p-[30px] text-center lg:max-w-[450px] lg:p-[0]">
          <img
            className="mx-auto mb-[35px] w-[200px] lg:w-[292px]"
            src={WinLaptop}
            alt=""
          />
          <p className="mb-[12px] font-[20px] leading-[24px] tracking-[0.15px] text-[#FCFCFC] lg:mb-[16px] lg:text-[20px]">
            You have been added to the Windows Waitlist.
          </p>
          <p className="font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC] lg:text-[20px]">
            We will email you as soon as it's released.
          </p>
          <p className="mt-[50px] font-normal leading-[24px] tracking-[0.15px] text-[#DCDEE4] lg:mt-[123px] lg:text-[16px]">
            Need help from a human DJ?{" "}
            <a className="text-[#99A0C5]" href="mailto:support@density.one">
              support@density.one
            </a>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="h-[calc(100%-120px)] pt-6 md:mt-[0px]">
      {showDownloadInstructions && deviceInfo.type === DeviceType.Desktop ? (
        <div className="flex h-[100%] flex-col justify-evenly px-6">
          <div className="scrollbar-hide lg:max-h[100%] flex flex-col overflow-auto p-6 text-center lg:flex-row">
            <div className="flex flex-col">
              <img
                className="mx-auto mb-[27px] h-[131px] w-[330px] object-contain"
                src={MacLaptop}
                alt=""
              />
              <p className="mb-[37px] font-['Inter'] text-[20px] leading-[24px] tracking-[0.15px] text-[#FCFCFC]">
                Open the Density Desktop app
              </p>
              <a
                href={getDownloadLink(deviceInfo)}
                download
              >
                <Button
                  fullWidth
                  variant={"contained"}
                  className="buttonWithIcon"
                  onClick={() => handleDownloadClick("Instruction page")}
                  sx={{ height: "44px", fontSize: "13px" }}
                >
                  <span> Download </span>
                </Button>
              </a>
            </div>
            <div className="mx-[56px] my-6 flex h-[100%] pt-[65px] lg:my-0">
              <img
                className="mx-auto h-[24px] w-[16px] rotate-90 lg:rotate-0"
                src={ChevronIcon}
                alt=""
              />
            </div>
            <div className="flex flex-col pt-[36px]">
              <div className="mx-auto my-0 mb-[4px] flex h-[84px] w-[84px] items-center justify-center rounded-full border-[1px] border-[#62667C] xl:mb-[78px]">
                <span className="text-[34px] font-normal leading-[36px] text-white">
                  2
                </span>
              </div>
              <p className=" mx-auto max-w-[30ch] break-all text-center text-[20px] leading-[36px] tracking-[0.15px] text-[#FCFCFC]">
                Log in using:
                <br />
                {userEmail}
              </p>
              <p className="text-[16px] leading-[24px] tracking-[0.1px] text-[#FCFCFC99]">
                Not your email?{" "}
                <span
                  className="cursor-pointer text-[#737BAE] underline"
                  onClick={changeEmail}
                >
                  Update it here.
                </span>
              </p>
            </div>
            <div className="mx-[56px] my-6 flex h-[100%] pt-[65px] lg:my-0">
              <img
                className="mx-auto h-[24px] w-[16px] rotate-90 lg:rotate-0"
                src={ChevronIcon}
                alt=""
              />
            </div>
            <div className="flex flex-col pt-[36px]">
              <div className="mx-auto my-0 mb-[4px] flex h-[84px] w-[84px] items-center justify-center rounded-full border-[1px] border-[#62667C] xl:mb-[78px]">
                <span className="text-[34px] font-normal leading-[36px] text-white">
                  3
                </span>
              </div>
              <p className="mx-auto max-w-[23ch] text-[20px] font-medium leading-[36px] tracking-[0.15px] text-[#FCFCFC] lg:mb-[30px]">
                Point and click to create a set in the DAW
              </p>
              <p className="invisible hidden cursor-none text-[12px] font-medium leading-[18px] tracking-[0.1px] text-[#FCFCFC99] lg:block">
                Not your email?{" "}
                <span
                  className="cursor-pointer text-[#737BAE] underline"
                  onClick={changeEmail}
                >
                  Update it here.
                </span>
              </p>
            </div>
            <div className="mx-[56px] my-6 flex h-[100%] pt-[65px] lg:my-0">
              <img
                className="mx-auto h-[24px] w-[16px] rotate-90 lg:rotate-0"
                src={ChevronIcon}
                alt=""
              />
            </div>
            <div className="lg:w-[300px]">
              <img
                className="mx-auto mb-[52px] h-[154px] w-[225px] object-contain"
                src={FinalStepLaptopAndRec}
                alt=""
              />
              <div>
                <p className="text-[18px] leading-[24px] tracking-[0.15px] text-[#FCFCFC] xl:mb-[24px]">
                  Connect your laptop to your decks to record a set.
                </p>
                <p className="text-[14px] leading-[24px] tracking-[0.15px] text-[#FCFCFC]">
                  Upgrade to the Density Hardware for club environments.
                  Available in the app.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center text-center lg:flex-row">
            <div className="min-h-[120px] pt-[16px]">
              <div>
                <p className="text-[16px] font-normal leading-[24px] tracking-[0.15px] text-[#DCDEE4]">
                  Need help from a human DJ?{" "}
                  <a
                    className="text-[#99A0C5]"
                    href="mailto:support@density.one"
                  >
                    support@density.one
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {deviceInfo.type === DeviceType.Desktop &&
              (deviceInfo.os === OS.MacOS || deviceInfo.os === OS.Windows) && (
              <div
                className=" flex h-[100%] max-w-[330px] flex-col justify-evenly"
                // style={{ display: isMobileOrTablet ? "none" : "block" }}
              >
                {isInvite && (
                  <div className="relative z-10 mx-auto mb-[30px] mt-[-53px] h-[120px] w-[120px] overflow-hidden rounded-full">
                    <img
                      className={`${
                        localStorage.getItem("imgUrl") === DensityLogo
                          ? "object-contain p-2.5"
                          : "object-cover p-0"
                      }`}
                      alt={referrer}
                      src={localStorage.getItem("imgUrl") || ""}
                    />
                  </div>
                )}
                <p className="px-[15] text-center text-[20px] font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC]">
                  {/* {isComingFromHardware && "Your order is on the way and you're subscribed to premium!"} */}
                  {isComingFromHardware && "Your order is on the way!"}
                  {isInvite && `from ${referrer}`} <br />{" "}
                  {!isComingFromHardware && "Download the Density Desktop "}
                  {!isComingFromHardware && "app to create your first set"}
                </p>
                <a
                  href={getDownloadLink(deviceInfo)}
                  download
                >
                  <Button
                    fullWidth
                    variant={"contained"}
                    className="buttonWithIcon"
                    onClick={() =>
                      handleDownloadClick("Registration Step - Download")
                    }
                    sx={{ height: "44px", fontSize: "13px" }}
                  >
                    <span> Download </span>
                  </Button>
                </a>
                <img className="h-auto w-[350px]" src={MacLaptop} alt="" />
                {!isComingFromHardware && (
                  <>
                    <p className="text-center text-[20px] leading-[24px] tracking-[0.15px] text-[#717274]">
                      Hardware can be purchased in the app
                    </p>
                  </>
                )}
              </div>
            )}

          {(deviceInfo.type !== DeviceType.Desktop ||
              (deviceInfo.os !== OS.MacOS && deviceInfo.os !== OS.Windows)) && (
            <div
              className="flex h-[100%] flex-col justify-evenly p-6 text-center"
              // style={{ display: isMobileOrTablet ? "block" : "none" }}
            >
              <p className="mx-auto  max-w-[25ch] break-all text-center text-[20px] font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC]">
                Your download link was sent to <br /> {userEmail}
              </p>
              <p className="text-[12px] font-medium leading-[18px] tracking-[0.1px] text-[#FCFCFC99]">
                Not your email?{" "}
                <span
                  className="cursor-pointer text-[#737BAE] underline"
                  onClick={changeEmail}
                >
                  Update it here.
                </span>
              </p>
              <p className="mb-[24px] text-[14px] font-semibold leading-[24px] tracking-[0.1px] text-[#FCFCFC99]">
                Install the Density Desktop app on
                <br /> your computer
              </p>
              <img
                className="h-auto max-w-[500px] object-contain"
                src={MacLaptop}
                alt=""
              />
            </div>
          )}
        </>
      )}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(2px)",
        }}
        open={loading}
      >
        <Box
          width={"100px"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
        >
          <CircularProgress
            sx={{
              marginBottom: "20px",
            }}
            color="inherit"
          />
          Loading ...
        </Box>
      </Backdrop>
    </div>
  );
};
